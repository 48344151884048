/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ProfileExternalProfileVisitMutationVariables = Types.Exact<{
  input: Types.ExternalVompInput;
}>;

export type ProfileExternalProfileVisitMutation = {
  __typename?: 'Mutation';
  externalProfileVisit?: {
    __typename?: 'ExternalVompResult';
    error?: {
      __typename?: 'ExternalVompError';
      message?: string | null;
      errors?: Array<string> | null;
    } | null;
  } | null;
};

export const ProfileExternalProfileVisitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'profileExternalProfileVisit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExternalVompInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalProfileVisit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'error' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileExternalProfileVisitMutation,
  ProfileExternalProfileVisitMutationVariables
>;
