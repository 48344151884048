import { useMutation, useQuery } from '@apollo/client';
import { Redirect, useParams } from '@reach/router';
import { useEffect, useRef, type FC } from 'react';

import { VompPlatformEnum } from '@xing-com/crate-common-graphql-types';
import type { PageViewTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import type { Xinglet } from '@xing-com/crate-xinglet';
import { useHost, XingletLoader } from '@xing-com/crate-xinglet';

import { LegalNoticeDocument } from './graphql/legal-notice.gql-types';
import type { ProfileLoProfileQuery } from './graphql/profile.gql-types';
import { ProfileLoProfileDocument } from './graphql/profile.gql-types';
import { ProfileExternalProfileVisitDocument } from './graphql/profileExternalProfileVisit.gql-types';

type AdobeTrackingProps = {
  PropContextDimension2: string;
  PropContextAddition: string;
};

const ProfileVisitorsTracing: FC<{ pageName: string }> = ({ pageName }) => {
  const lastTracedPageName = useRef<string | null>(null);

  const [profileVisit] = useMutation(ProfileExternalProfileVisitDocument);

  if (lastTracedPageName.current === pageName) return;
  if (typeof document === 'undefined') return;

  const ipAddress = '127.0.0.1'; // TODO: Collect it in the backend instead
  const referer = typeof document !== 'undefined' ? document.referrer : '';
  const platform = VompPlatformEnum.Web;

  profileVisit({
    variables: {
      input: {
        visitor: { ipAddress },
        visitee: { pageName },
        visit: { referer, platform },
      },
    },
    onCompleted(data) {
      const errorInfo = data?.externalProfileVisit?.error;
      if (errorInfo)
        console.error(`${errorInfo.message}: ${errorInfo.errors?.join(', ')}`);
    },
    onError(error) {
      console.error(error);
    },
  });

  lastTracedPageName.current = pageName;

  return null;
};

const usePageViewTracking = (data?: ProfileLoProfileQuery): void => {
  const { track } = useTracking<PageViewTrackingEvent<AdobeTrackingProps>>();

  useEffect(() => {
    const qualityIndex = data?.profilePageMeta?.qualityIndex || 0;
    const qualityVariant = data?.profilePageMeta?.qualityVariant;
    const bucketedQualityIndex = Math.ceil(qualityIndex / 5) * 5;
    const qualityIndexStringValue = qualityIndex
      ? bucketedQualityIndex + '%'
      : 'nothing';

    track({
      type: 'pageview',
      channel: 'wbm/Profile_LO',
      page: 'wbm/Profile_LO/profile/show',
      PropContextDimension2: `profile_lo_${qualityIndexStringValue}`,
      PropContextAddition: `A-200|${qualityVariant}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const ProfileViewLoader: FC<{ pageName: string }> = ({ pageName }) => {
  const host = useHost();

  const { data, loading, error } = useQuery(ProfileLoProfileDocument, {
    variables: { id: pageName, idAsString: pageName },
    errorPolicy: 'all',
  });

  const { data: legalNoticeData, error: legalNoticeError } = useQuery(
    LegalNoticeDocument,
    {
      variables: { id: pageName },
      errorPolicy: 'all',
      ssr: false,
    }
  );

  usePageViewTracking(data);

  if (error || legalNoticeError) {
    console.error(error ?? legalNoticeError);

    if (!data || !data.xingIdWithError) {
      return (
        <XingletLoader
          name="@xing-com/crate-communication-error-pages"
          errorType="NotFound"
        />
      );
    }
  }

  if (loading) return 'Loading...';

  if (!data || !data.xingIdWithError) return 'Loading....';

  const { xingIdWithError } = data;
  const legalInformation =
    legalNoticeData?.profileLegalInformation?.displayContent;

  if (xingIdWithError.__typename === 'ResponseError') {
    const { errorCode } = xingIdWithError;
    if (errorCode === 403) return host.redirectToLogin(undefined, 301);
    return (
      <XingletLoader
        name="@xing-com/crate-communication-error-pages"
        errorStatusCode={errorCode || 500}
        errorType={errorCode === 404 ? 'NotFound' : 'Generic'}
      />
    );
  }
  return (
    <>
      <ProfileVisitorsTracing pageName={pageName} />
      <XingletLoader
        name="@xing-com/crate-profile-main-logged-out"
        pageName={pageName}
        data={data}
        isProfilePreviewPage={false}
        legalInformation={legalInformation}
        xingId={xingIdWithError}
      />
    </>
  );
};

export const ProfileViewRouter: FC = () => {
  const host = useHost();

  const { pageName } = useParams<{ pageName?: string }>() ?? {};

  if (!pageName) {
    return <Redirect to="/people" />;
  }

  if (pageName === 'my_profile') {
    return host.redirectToLogin();
  }

  return <ProfileViewLoader pageName={pageName} />;
};

export default class MainLoaderLoggedOut implements Xinglet {
  public getComponent(): FC {
    return ProfileViewRouter;
  }
}

export type { ProfileLoProfileQuery } from './graphql/profile.gql-types';
