/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ProfileLoProfileQueryVariables = Types.Exact<{
  id: Types.Scalars['SlugOrID']['input'];
  idAsString: Types.Scalars['String']['input'];
}>;

export type ProfileLoProfileQuery = {
  __typename?: 'Query';
  profilePageMeta?: {
    __typename?: 'ProfilePageMeta';
    pageTitle?: string | null;
    indexable?: boolean | null;
    pageCanonical?: string | null;
    pageDescription?: string | null;
    socialMediaImage?: string | null;
    socialMediaDescription?: string | null;
    socialMediaTitle?: string | null;
    scrambledProfileUrl?: string | null;
    qualityIndex?: number | null;
    qualityVariant?: string | null;
  } | null;
  profileStructuredData?: {
    __typename?: 'ProfileStructuredData';
    context?: string | null;
    type?: string | null;
    sameAs?: string | null;
    givenName?: string | null;
    familyName?: string | null;
    jobTitle?: string | null;
    honorificPrefix?: string | null;
    honorificSuffix?: string | null;
    name?: string | null;
    image?: string | null;
    workLocation?: {
      __typename?: 'ProfileStructuredDataWorkLocation';
      type?: string | null;
      address?: {
        __typename?: 'ProfileStructuredDataWorkAddress';
        type?: string | null;
        addressCountry?: string | null;
        addressLocality?: string | null;
      } | null;
    } | null;
    worksFor?: Array<{
      __typename?: 'ProfileStructuredDataWorkExperience';
      type?: string | null;
      name?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      worksFor?: {
        __typename?: 'ProfileStructuredDataWorkOrganization';
        type?: string | null;
        name?: string | null;
        location?: {
          __typename?: 'ProfileStructuredDataWorkAddress';
          type?: string | null;
          addressCountry?: string | null;
          addressLocality?: string | null;
        } | null;
      } | null;
    } | null> | null;
    makesOffer?: Array<{
      __typename?: 'ProfileStructuredDataOfferAndSeeks';
      type?: string | null;
      name?: string | null;
    } | null> | null;
    seeks?: Array<{
      __typename?: 'ProfileStructuredDataOfferAndSeeks';
      type?: string | null;
      name?: string | null;
    } | null> | null;
    alumniOf?: Array<{
      __typename?: 'ProfileStructuredDataAlumni';
      type?: string | null;
      name?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      alumniOf?: {
        __typename?: 'ProfileStructuredDataAlumniOrganization';
        type?: string | null;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
  xingIdWithError?:
    | {
        __typename: 'ResponseError';
        errorCode?: number | null;
        errorMessage?: string | null;
      }
    | {
        __typename: 'XingId';
        id: string;
        firstName: string;
        lastName: string;
        pageName: string;
        displayName: string;
        gender?: Types.Gender | null;
        academicTitle?: Types.AcademicTitle | null;
        hasDefaultHeaderImage: boolean;
        profileImage?: Array<{
          __typename?: 'ProfileImage';
          size: Types.ProfileImageSize;
          url: string;
        } | null> | null;
        headerImage?: Array<{
          __typename?: 'HeaderImage';
          url: string;
          reference: string;
        }> | null;
        userFlags?: {
          __typename?: 'UserFlags';
          displayFlag?: Types.UserFlagsDisplayFlag | null;
        } | null;
        status?: {
          __typename?: 'ProfilePersonalStatus';
          localizationValue?: string | null;
        } | null;
        location?: {
          __typename?: 'XingIdLocation';
          city?: string | null;
          displayLocation?: string | null;
          country?: {
            __typename?: 'Country';
            countryCode: Types.CountryCodesAlpha2;
            localizationKey: string;
            localizationValue: string;
          } | null;
        } | null;
        occupations?: Array<{
          __typename?: 'XingIdOccupation';
          category: Types.XingIdOccupationCategory;
          summary: any;
          headline: string;
          subline: string;
          links?: Array<{
            __typename?: 'XingIdOccupationLink';
            url: string;
            urn: string;
          }> | null;
        } | null> | null;
      }
    | null;
  profileWorkExperience?: {
    __typename?: 'ProfileWorkExperienceCollection';
    collection?: Array<{
      __typename?: 'ProfileWorkExperience';
      jobTitle?: string | null;
      beginDate?: string | null;
      endDate?: string | null;
      currentCompany?: boolean | null;
      companyName?: string | null;
      localizedTimeString?: string | null;
      companyNotes?: string | null;
      company?: {
        __typename?: 'Company';
        links?: {
          __typename?: 'CompanyLinks';
          default?: string | null;
          public?: string | null;
        } | null;
        logos?: {
          __typename?: 'CompanyLogos';
          logo256px?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  profileEducation?: {
    __typename?: 'ProfileEducationCollection';
    education?: Array<{
      __typename?: 'ProfileEducation';
      primarySchool?: boolean | null;
      subject?: string | null;
      degree?: string | null;
      schoolName?: string | null;
      schoolNotes?: string | null;
      beginDate?: string | null;
      endDate?: string | null;
      localizedTimeString?: string | null;
    } | null> | null;
  } | null;
  profileLanguageSkills?:
    | { __typename: 'LoggedInProfileLanguageCollection' }
    | {
        __typename: 'LoggedOutProfileLanguageCollection';
        collection?: Array<{
          __typename?: 'LoggedOutProfileLanguageSkills';
          language?: string | null;
          skillLevelId?: number | null;
          displayLanguage?: string | null;
        } | null> | null;
      }
    | null;
  profileSkills?: {
    __typename: 'ProfileSkills';
    skills?: Array<string> | null;
    topSkills?: Array<string> | null;
  } | null;
  similarProfiles?: {
    __typename?: 'RelatedProfileCollection';
    collection: Array<{
      __typename?: 'RelatedProfile';
      id?: string | null;
      academicTitle?: string | null;
      lastName?: string | null;
      firstName?: string | null;
      businessCity?: string | null;
      businessCountry?: string | null;
      hasPhoto?: boolean | null;
      occupationTitle?: string | null;
      pageName?: string | null;
      photoUrl?: string | null;
    } | null>;
  } | null;
  peopleUserBag?: {
    __typename?: 'PeopleUserBagConnection';
    total: number;
    userBagUrl?: string | null;
    edges?: Array<{
      __typename?: 'PeopleUserBagConnectionEdge';
      node: {
        __typename?: 'RelatedProfile';
        id?: string | null;
        academicTitle?: string | null;
        lastName?: string | null;
        firstName?: string | null;
        businessCity?: string | null;
        businessCountry?: string | null;
        hasPhoto?: boolean | null;
        occupationTitle?: string | null;
        pageName?: string | null;
        photoUrl?: string | null;
      };
    } | null> | null;
  } | null;
};

export const ProfileLoProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'profileLoProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SlugOrID' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'idAsString' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilePageMeta' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pageTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indexable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageCanonical' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageDescription' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'socialMediaImage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'socialMediaDescription' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'socialMediaTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scrambledProfileUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qualityIndex' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qualityVariant' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileStructuredData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'idAsString' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sameAs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'givenName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'familyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'honorificPrefix' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'honorificSuffix' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workLocation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addressCountry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addressLocality' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'worksFor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'worksFor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressCountry',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'addressLocality',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'makesOffer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'seeks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alumniOf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'alumniOf' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'xingIdWithError' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'XingId' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profileImage' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'EnumValue', value: 'SQUARE_256' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'size' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headerImage' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'options' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '984' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '480' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'reference',
                                      },
                                      value: {
                                        kind: 'StringValue',
                                        value: '984',
                                        block: false,
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '750' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '366' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'reference',
                                      },
                                      value: {
                                        kind: 'StringValue',
                                        value: '750',
                                        block: false,
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'width' },
                                      value: { kind: 'IntValue', value: '375' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'height' },
                                      value: { kind: 'IntValue', value: '183' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: {
                                        kind: 'Name',
                                        value: 'reference',
                                      },
                                      value: {
                                        kind: 'StringValue',
                                        value: '375',
                                        block: false,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reference' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userFlags' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayFlag' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'academicTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasDefaultHeaderImage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'localizationValue',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'countryCode',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'localizationKey',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'localizationValue',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayLocation' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'occupations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'summary' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'headline' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subline' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'links' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'urn' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ResponseError' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errorMessage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileWorkExperience' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beginDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentCompany' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizedTimeString' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'links' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'default' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'public' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logos' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logo256px' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyNotes' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileEducation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'education' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'primarySchool' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subject' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'degree' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'schoolName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'schoolNotes' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'beginDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'localizedTimeString' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileLanguageSkills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'LoggedOutProfileLanguageCollection',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'collection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'language' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'skillLevelId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayLanguage' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileSkills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'skills' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topSkills' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'similarProfiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'profileIdentifier' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'academicTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessCity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessCountry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPhoto' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'occupationTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'photoUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'peopleUserBag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '10' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'academicTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'businessCity' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'businessCountry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasPhoto' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'occupationTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'photoUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userBagUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileLoProfileQuery,
  ProfileLoProfileQueryVariables
>;
